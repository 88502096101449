._formField_uazmk_1200 label[for="file"],
._formField_uazmk_1200 input[type="file"],
._formField_uazmk_1200 label[for="src"] {
  display: none;
}

._formField_uazmk_1200:first-of-type:before {
  content: "Add an image from a URL:";
  font-size: 12px;
}
