.dropdown {
  @apply hidden;
}

/* .dropdown-container:hover > .dropdown, */
/* .dropdown:hover,
.dropdown-button:focus ~ .dropdown {
  @apply block;
} */

.dropdown-button.show .caret {
  transform: rotate(180deg);
}
