/* Works on Firefox */
.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
.custom-scroll::-webkit-scrollbar {
  width: 12px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: rgb(28 37 44);
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(242 243 248);
  border-radius: 20px;
}