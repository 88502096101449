[type="range"] {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
  margin: 0;
  padding: 0;
  height: 1.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
}
[type="range"],
[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
[type="range"]::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.5em;
  background: #d9d9d9;
  border-radius: 4px;
}
.js [type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(#8dc740, #8dc740) 0 / var(--sx) 100% no-repeat #d9d9d9;
}
[type="range"]::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.5em;
  background: #d9d9d9;
}
[type="range"]::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.5em;
  background: #d9d9d9;
}
[type="range"]::-moz-range-progress {
  height: 0.25em;
  background: #8dc740;
}
[type="range"]::-ms-fill-lower {
  height: 0.25em;
  background: #8dc740;
}
[type="range"]::-webkit-slider-thumb {
  margin-top: -0.625em;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #00a89d;
  background: linear-gradient(#00a89d, #8dc740);
}
[type="range"]:disabled::-webkit-slider-thumb {
  display: none;
}
[type="range"]::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #00a89d;
  background: linear-gradient(#00a89d, #8dc740);
}
[type="range"]::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #00a89d;
  background: linear-gradient(#00a89d, #8dc740);
}
[type="range"]::-ms-tooltip {
  display: none;
}
