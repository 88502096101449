.input-main {
  @apply font-sans w-full;
  font-size: 16px;
}

.input-main.disabled {
  cursor: not-allowed;
}

.input-box {
  @apply relative mb-5;
}

.sm .input-box {
  @apply mb-2;
}

.input {
  @apply w-full border border-dark-grey rounded-lg;
  outline: none;
  height: 64px;
  padding: 1rem 1rem 0rem 1rem;
  z-index: 1;
  position: relative;
  font-size: inherit;
}

.input.error {
  @apply border-red-500
}

.no-label .input:not(textarea) {
  padding-top: 0;
  height: 54px;
}

textarea.input {
  height: auto;
  padding-top: 1.5rem;
}

.sm .input {
  @apply text-base;
  padding: 0.6rem 0.6rem 0rem 0.6rem;
  height: 50px;
  font-size: 16px;
}

.sm.no-label .input:not(textarea) {
  padding-top: 0;
  height: 40px;
}

.sm textarea.input {
  padding-top: 1rem;
}

.disabled .input {
  @apply bg-soft-grey;
}

.input:focus {
  @apply border-2 border-dark-grey ring-0;
  outline: none;
}

.label {
  @apply absolute pointer-events-none block text-grey-50;
  transform: translate(16px, 21px) scale(1);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  top: 0;
}

.sm .label {
  @apply text-sm;
  transform: translate(10px, 15px) scale(1);
}

.label-focus {
  @apply text-grey-50;
  outline: none;
  transform: translate(0, -1px) scale(0.75);
  transform-origin: top left;
}

.sm .label-focus {
  transform: translate(0, 5px) scale(0.75);
}

.input-underline {
  border-bottom: 1px solid transparent;
}

.input-underline:after {
  @apply absolute left-0 right-0 pointer-events-none border-primary;
  bottom: -0.2rem;
  content: "";
  transform-origin: top;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: scaleY(0);
  border-bottom-width: 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-style: solid;
  z-index: 0;
}

.input:focus~.input-underline:after {
  transform: scaleY(1);
}

textarea.input:focus~.input-underline:after {
  transform: scaleY(0.7);
}

.input:focus+.label,
.input:not(:placeholder-shown)+.label {
  @apply label-focus;
  transform: translate(16px, 9px) scale(0.75);
  transform-origin: top left;
}

.sm .input:focus+.label,
.sm .input:not(:placeholder-shown)+.label {
  transform: translate(10px, 5px) scale(0.75);
}

.input:not(:focus, .show-placeholder)::placeholder {
  color: transparent;
}

/* enable to leave border-bottom in modified color when the field is populated */
/*  
.input:not(:placeholder-shown) ~ .input-underline:after {
  	transform: scaleX(1);
} 
*/
.has-prepend {
  padding-left: 2.1rem;
}

.has-append {
  padding-right: 5rem;
}

.sm .input.has-prepend {
  padding-left: 1.6rem;
}

.input.has-prepend+.label {
  padding-left: 1.5rem;
}

.prepend {
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input:focus~.prepend,
.input.show-placeholder~.prepend,
.input:not(:placeholder-shown)~.prepend {
  padding-top: 1.6rem !important;
}

.sm .input:focus~.prepend,
.sm .input.show-placeholder~.prepend,
.sm .input:not(:placeholder-shown)~.prepend {
  @apply text-sm;
  padding: 0.6rem !important;
  padding-top: 1.25rem !important;
}

.prepend * {
  @apply text-base;
}

.sm .prepend * {
  @apply text-sm;
}