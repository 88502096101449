.markdown ol,
.markdown ul,
.markdown menu {
  list-style: revert;
  margin: inherit;
  padding: revert;
}

.markdown ul.contains-task-list {
  list-style: none;
  padding-left: 25px;
}

.markdown-table th,
.markdown-table td {
  border-width: 1px;
  text-align: center;
  padding: 4px 10px;
}

.markdown a {
  text-decoration: underline;
}

.markdown blockquote,
.markdown dl,
.markdown dd,
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6,
.markdown hr,
.markdown figure,
.markdown p,
.markdown pre {
  margin: revert;
  font-size: revert;
}
